.loader {
    height: 4px;
    width: 100%;
    --c:no-repeat linear-gradient(#6100ee 0 0);
    background: var(--c),var(--c),#d7b8fc;
    background-size: 60% 100%;
    animation: l16 3s infinite;
    margin-top: 5px;
    margin-bottom: -5px;
  }
  @keyframes l16 {
    0%   {background-position:-150% 0,-150% 0}
    66%  {background-position: 250% 0,-150% 0}
    100% {background-position: 250% 0, 250% 0}
  }
  .cont{
    width: "100%";
  }

/* ------ */
  .spinner {
    position: relative;
    width: 15.7px;
    height: 15.7px;
 }
 
 .spinner div {
    animation: spinner-4t3wzl 1.875s infinite backwards;
    background-color: #474bff;
    border-radius: 50%;
    height: 100%;
    position: absolute;
    width: 100%;
 }
 
 .spinner div:nth-child(1) {
    animation-delay: 0.15s;
    background-color: rgba(71,75,255,0.9);
 }
 
 .spinner div:nth-child(2) {
    animation-delay: 0.3s;
    background-color: rgba(71,75,255,0.8);
 }
 
 .spinner div:nth-child(3) {
    animation-delay: 0.45s;
    background-color: rgba(71,75,255,0.7);
 }
 
 .spinner div:nth-child(4) {
    animation-delay: 0.6s;
    background-color: rgba(71,75,255,0.6);
 }
 
 .spinner div:nth-child(5) {
    animation-delay: 0.75s;
    background-color: rgba(71,75,255,0.5);
 }
 
 @keyframes spinner-4t3wzl {
    0% {
       transform: rotate(0deg) translateY(-200%);
    }
 
    60%, 100% {
       transform: rotate(360deg) translateY(-200%);
    }
 }